import {
  Book,
  ChevronDown,
  MessageCircle,
  UserCheck,
  X,
  Zap
} from 'lucide-react';
import React, { useEffect, useState } from 'react';

const PsychologyBotLandingPage = () => {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [roadmapStep, setRoadmapStep] = useState(0);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);

  const testimonials = [
    {
      id: 1,
      text: "تجربه‌ای متفاوت از مشاوره روانشناسی که به من کمک کرد خودم را بهتر بشناسم.",
      name: "محمد",
      age: 29,
      profileImage: "https://www.svgrepo.com/show/382109/male-avatar-boy-face-man-user-7.svg"
    },
    {
      id: 2,
      text: "راه‌حل‌های عملی و کاربردی که توانستم در زندگی روزمره‌ام استفاده کنم.",
      name: "ترانه",
      age: 35,
      profileImage: "https://www.svgrepo.com/show/382097/female-avatar-girl-face-woman-user-9.svg"
    },
    {
      id: 3,
      text: "برای اولین بار احساس کردم کسی واقعاً به عمق احساساتم گوش می‌دهد.",
      name: "فاطمه",
      age: 42,
      profileImage: "https://www.svgrepo.com/show/382097/female-avatar-girl-face-woman-user-9.svg"
    }
  ];

  // Roadmap animation
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTestimonial((prev) => 
        (prev + 1) % testimonials.length
      );
    }, 5000);
  
    const roadmapInterval = setInterval(() => {
      setRoadmapStep((prev) => (prev + 1) % 4);
    }, 3000);
  
    return () => {
      clearInterval(interval);
      clearInterval(roadmapInterval);
    };
  }, [testimonials.length]);

  const RobotDescriptionModal = () => (
    <div 
      className={`fixed inset-0 z-50 flex items-center justify-center p-4 
        ${isModalOpen ? 'visible' : 'invisible'}`}
      style={{
        backgroundColor: 'rgba(0,0,0,0.5)'
      }}
    >
      <div 
        className={`bg-white rounded-2xl p-8 max-w-2xl w-full max-h-[80vh] overflow-y-auto transform transition-all duration-300 
          ${isModalOpen 
            ? 'scale-100 opacity-100' 
            : 'scale-90 opacity-0'}`}
      >
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-purple-800">
            روان‌شناس: همراه هوشمند سلامت روان
          </h2>
          <button 
            onClick={() => setIsModalOpen(false)}
            className="text-gray-600 hover:text-purple-600"
          >
            <X className="w-8 h-8" />
          </button>
        </div>

        <div className="space-y-4 text-gray-700 leading-relaxed">
          <p>
            روان‌شناس یک پلتفرم هوشمند مشاوره روانشناسی است که با ترکیب فناوری هوش مصنوعی و تخصص متخصصان روانشناسی، راهکارهای تخصصی برای چالش‌های روحی و روانی ارائه می‌دهد.
          </p>

          <h3 className="text-xl font-semibold text-purple-700 mt-4">
            چگونه کار می‌کنیم؟
          </h3>

          <ul className="list-disc pr-5 space-y-2">
            <li>
              <strong>مشاوره تخصصی:</strong> مشاوره‌های شخصی‌سازی شده با استفاده از الگوریتم‌های پیشرفته
            </li>
            <li>
              <strong>تحلیل هوشمند:</strong> بررسی دقیق اطلاعات و ارائه راهکارهای علمی
            </li>
            <li>
              <strong>محرمانگی کامل:</strong> حفظ اطلاعات شخصی با بالاترین سطح امنیت
            </li>
          </ul>

          <h3 className="text-xl font-semibold text-purple-700 mt-4">
            خدمات ما
          </h3>

          <div className="grid md:grid-cols-2 gap-4">
            <div className="bg-purple-50 p-4 rounded-lg">
              <h4 className="font-bold mb-2">مشاوره آنلاین</h4>
              <p>مشاوره فوری با متخصصان روانشناسی</p>
            </div>
            <div className="bg-purple-50 p-4 rounded-lg">
              <h4 className="font-bold mb-2">آزمون‌های روانشناسی</h4>
              <p>ارزیابی تخصصی شخصیت و سلامت روان</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const roadmapSteps = [
    {
      icon: <Zap className="w-8 h-8 text-yellow-600" />,
      title: "شروع",
      description: "افتتاح حساب و تنظیمات اولیه"
    },
    {
      icon: <Book className="w-8 h-8 text-pink-600" />,
      title: "ارزیابی",
      description: "تکمیل پرسشنامه‌های اولیه"
    },
    {
      icon: <MessageCircle className="w-8 h-8 text-blue-600" />,
      title: "مشاوره",
      description: "گفتگو با مشاور متخصص"
    },
    {
      icon: <UserCheck className="w-8 h-8 text-green-600" />,
      title: "پیگیری",
      description: "دریافت راهکارهای تخصصی"
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-50 to-pink-50 text-right" dir="rtl">

      <nav className="fixed top-0 left-0 right-0 z-50 bg-white shadow-md">
          <div className="container mx-auto flex justify-between items-center p-4">
            <div className="flex items-center space-x-4 reverse-space-x">
            <button 
              onClick={() => setIsModalOpen(true)}
              className="bg-purple-600 text-white px-6 py-2 rounded-full hover:bg-purple-700 transition-colors"
            >
              درباره ما
            </button>
            </div>
            {/* <a 
              href="https://t.me/RavanShenasRobot"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-purple-600 text-white px-6 py-2 rounded-full hover:bg-purple-700 transition-colors"
            >
              اتصال به ربات
            </a> */}
          </div>
      </nav>

      {/* Previous navigation and hero section remain the same */}
      
      {/* Hero Section - Modify the "بیشتر بدانید" button */}
      <header className="container mx-auto px-4 pt-24 pb-16 text-center">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-4xl font-bold text-gray-800 mb-6">
            همراه شما در مسیر سلامت روان
          </h1>
          <div className="relative">
            <p 
              className={`text-xl text-gray-600 mb-8 leading-relaxed overflow-hidden relative 
                ${!isDescriptionExpanded ? 'max-h-24 ' : 'max-h-full'}
                after:absolute after:bottom-0 after:left-0 after:right-0 after:h-16 
                ${!isDescriptionExpanded ? 'after:bg-gradient-to-t after:from-white after:to-transparent' : ''}`}
            >
              روان‌شناس، پلتفرم مشاوره روانشناسی آنلاین که با استفاده از هوش مصنوعی و متخصصان مجرب، راهکارهای تخصصی برای چالش‌های روحی و روانی شما ارائه می‌دهد. ما باور داریم که هر فرد منحصر به فرد است و نیاز به مراقبت شخصی‌سازی شده دارد.
              
              {isDescriptionExpanded && (
                <span>
                  ما با ترکیب فناوری پیشرفته و تخصص انسانی، یک تجربه مشاوره‌ای کاملاً امن، محرمانه و سفارشی را برای شما فراهم می‌کنیم. 
                  هدف ما کمک به شما برای درک عمیق‌تر از خودتان، مدیریت چالش‌های روانی و رسیدن به آرامش درونی است.
                </span>
              )}
            </p>
            
            {!isDescriptionExpanded && (
              <button 
                onClick={() => setIsDescriptionExpanded(true)}
                className="absolute bottom-0 left-1/2 transform -translate-x-1/2 
                  bg-purple-100 text-purple-700 px-4 py-2 rounded-full 
                  hover:bg-purple-200 transition-colors flex items-center"
              >
                بیشتر بدانید
                <ChevronDown className="mr-2" />
              </button>
            )}
          </div>
        </div>
      </header>

      {/* Roadmap Section - Interactive Quick Start */}
      <section className="container mx-auto px-4 py-16">
        <div className="bg-white rounded-2xl shadow-lg p-8">
          <h2 className="text-2xl font-bold text-center mb-8 text-purple-800">
            شروع سریع و آسان
          </h2>
          
          <div className="flex justify-between items-center">
            {roadmapSteps.map((step, index) => (
              <div 
                key={index} 
                className={`flex flex-col items-center transition-all duration-500 ${
                  roadmapStep === index 
                    ? 'scale-110 opacity-100' 
                    : 'opacity-50 scale-90'
                }`}
              >
                <div 
                  className={`w-16 h-16 rounded-full flex items-center justify-center mb-4 transition-all duration-500 ${
                    roadmapStep === index 
                      ? 'bg-purple-600 text-white' 
                      : 'bg-gray-100 text-gray-600'
                  }`}
                >
                  {step.icon}
                </div>
                <h3 className="font-bold mb-2">{step.title}</h3>
                <p className="text-sm text-gray-600 text-center">
                  {step.description}
                </p>
              </div>
            ))}
          </div>

          <div className="mt-8 text-center">
            <a 
              href="https://t.me/RavanShenasRobot"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-purple-600 text-white px-8 py-3 rounded-full hover:bg-purple-700 transition-colors"
            >
              شروع مشاوره
            </a>
          </div>
        </div>
      </section>

      {/* Testimonials Section - Remove navigation */}
      <section id="testimonials" className="container mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold text-center mb-12 text-gray-800">
          تجربیات کاربران
        </h2>
        
        <div className="max-w-2xl mx-auto">
          <div className="bg-white p-8 rounded-2xl shadow-xl relative overflow-hidden">
            {/* Testimonial Navigation */}
            {/* <div className="absolute top-1/2 transform -translate-y-1/2 flex justify-between w-full px-4 z-10">
              <button 
                onClick={nextTestimonial} 
                className="bg-purple-100 text-purple-600 p-2 rounded-full hover:bg-purple-200 transition-colors"
              >
                <ArrowRight />
              </button>
              <button 
                onClick={prevTestimonial} 
                className="bg-purple-100 text-purple-600 p-2 rounded-full hover:bg-purple-200 transition-colors"
              >
                <ArrowLeft />
              </button>
            </div> */}

            {/* Testimonial Content */}
            <div className="text-center">
              <img 
                src={testimonials[currentTestimonial].profileImage} 
                alt={testimonials[currentTestimonial].name}
                className="w-24 h-24 rounded-full mx-auto mb-6 border-4 border-purple-200"
              />

              <blockquote className="text-lg text-gray-700 italic mb-6 min-h-[120px]">
                "{testimonials[currentTestimonial].text}"
              </blockquote>

              <div className="text-gray-600">
                {testimonials[currentTestimonial].name}، {testimonials[currentTestimonial].age} ساله
              </div>

              {/* Pagination Dots */}
              <div className="flex justify-center mt-6">
                {testimonials.map((_, index) => (
                  <span 
                    key={index} 
                    className={`h-2 w-2 mx-1 rounded-full ${
                      index === currentTestimonial 
                        ? 'bg-purple-600' 
                        : 'bg-purple-200'
                    }`}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Description Modal */}
      <RobotDescriptionModal />
    </div>
  );
};

export default PsychologyBotLandingPage;