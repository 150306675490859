import { AlertCircle } from 'lucide-react';

import React, { useEffect, useState } from 'react';

const IPCheck = () => {
  const [loading, setLoading] = useState(true);
  const [isIranianIP, setIsIranianIP] = useState(false);
  const [error, setError] = useState(null);
  
  // Get redirect URL from query parameters
  const params = new URLSearchParams(window.location.search);
  const redirectUrl = params.get('redirect') || '/';

  useEffect(() => {
    const checkIP = async () => {
      try {
        // Fetching the IP address and location of the user
        const res = await fetch("https://ipwhois.app/json/");
        const location = await res.json();
    
        console.log("Location Data:", location);
    
        if (location && location.country_code) {
          const isIran = location.country_code === "IR";
          setIsIranianIP(isIran);
          if (isIran) {
            // Redirect if user is from Iran
            window.location.href = redirectUrl;
          }
        } else {
          setError("Failed to get location data");
        }
      } catch (error) {
        setError("Failed to fetch location");
      } finally {
        setLoading(false);
      }
    };
  
    checkIP();
  }, [redirectUrl]);
  

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-purple-50 to-pink-50 flex items-center justify-center" dir="rtl">
        <div className="bg-white p-8 rounded-2xl shadow-lg max-w-md w-full mx-4">
          <div className="text-center">
            <div className="animate-spin rounded-full h-12 w-12 border-4 border-purple-500 border-t-transparent mx-auto mb-4"></div>
            <p className="text-gray-600">در حال بررسی...</p>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-purple-50 to-pink-50 flex items-center justify-center" dir="rtl">
        <div className="bg-white p-8 rounded-2xl shadow-lg max-w-md w-full mx-4">
          <div className="text-center">
            <AlertCircle className="w-16 h-16 text-yellow-500 mx-auto mb-4" />
            <h1 className="text-2xl font-bold text-gray-800 mb-4">
              خطا در بررسی موقعیت
            </h1>
            <p className="text-gray-600 mb-6">
              مشکلی در بررسی موقعیت شما پیش آمده است. لطفاً دوباره تلاش کنید.
            </p>
            <button 
              onClick={() => window.location.reload()}
              className="bg-purple-600 text-white px-6 py-2 rounded-full hover:bg-purple-700 transition-colors"
            >
              تلاش مجدد
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (!isIranianIP) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-purple-50 to-pink-50 flex items-center justify-center" dir="rtl">
        <div className="bg-white p-8 rounded-2xl shadow-lg max-w-md w-full mx-4">
          <div className="text-center">
            <AlertCircle className="w-16 h-16 text-red-500 mx-auto mb-4" />
            <h1 className="text-2xl font-bold text-gray-800 mb-4">
              لطفاً فیلترشکن خود را خاموش کنید
            </h1>
            <p className="text-gray-600 mb-6">
              برای استفاده درگاه پرداخت، لازم است از داخل ایران و بدون VPN اقدام کنید.
            </p>
            <button 
              onClick={() => window.location.reload()}
              className="bg-purple-600 text-white px-6 py-2 rounded-full hover:bg-purple-700 transition-colors"
            >
              تلاش مجدد
            </button>
          </div>
        </div>
      </div>
    );
  }

  return null; // Will redirect if Iranian IP
};

export default IPCheck;