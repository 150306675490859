import React from 'react';
import ReactDOM from 'react-dom/client';
import IPCheck from './components/IPCheck';
import PaymentVerification from './components/VerifyPayment';
import './index.css';
import PsychologyBotLandingPage from './Main';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

// Get the current path
const path = window.location.pathname;

// Determine which component to render
const getComponent = () => {
  switch (path) {
    case '/check':
      return <IPCheck key={Date.now()} />;
    case '/verify-payment':
      return <PaymentVerification />;
    default:
      return <PsychologyBotLandingPage />;
  }
};

root.render(getComponent());

reportWebVitals();