import { AlertCircle, Check, Loader2 } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';

const PaymentVerification = () => {
  const [responseMessage, setResponseMessage] = useState('در حال بررسی تراکنش...');
  const [status, setStatus] = useState('loading'); // 'loading', 'success', 'error'
  const [details, setDetails] = useState(null);
  const hasFetched = useRef(false);
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    if (hasFetched.current) return;
    hasFetched.current = true;

    const params = new URLSearchParams(window.location.search);
    const authority = params.get('Authority');
    const currentDate = new Date().toLocaleString('fa-IR', { 
      timeZone: 'Asia/Tehran',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });

    if (authority) {
        fetch(`https://telegram.ravanshenas.com/api/payments?token=${authority}`)
          .then(response => response.json())
          .then(data => {
            if (!data.success) {
              setResponseMessage('مشکلی در تایید تراکنش به وجود آمد');
              setStatus('error');
                setDetails({
                authority: authority.slice(-15),
                refId: data.refId,
                amount: data.amount,
                date: currentDate
                });
            } else {
              setDetails({
                refId: data.refId,
                amount: new Intl.NumberFormat('fa-IR').format(data.amount),
                date: currentDate
              });
              setStatus('success');
              setShowConfetti(true);
            }
          })
          .catch(error => {
            console.error('Payment verification error:', error);
            setResponseMessage('مشکلی در ارتباط با سرور به وجود آمد');
            setStatus('error');
            setDetails({
              authority: authority.slice(-15),
              date: currentDate
            });
          });
      } else {
      setResponseMessage('پارامتر Authority یافت نشد.');
      setStatus('error');
    }
  }, []);

  const generateConfetti = () => {
    const colors = ['#FF69B4', '#8A2BE2', '#4B0082', '#9370DB'];
    const elements = [];
    const sizes = ['text-sm', 'text-base', 'text-lg', 'text-xl'];
    
    for (let i = 0; i < 60; i++) {
      elements.push(
        <div
          key={i}
          className={`falling-star ${sizes[Math.floor(Math.random() * sizes.length)]}`}
          style={{
            '--delay': `${Math.random() * 3}s`,
            '--rotation': `${Math.random() * 360}deg`,
            '--position': `${Math.random() * 100}%`,
            '--color': colors[Math.floor(Math.random() * colors.length)],
            '--duration': `${2 + Math.random() * 2}s`,
            '--scale': 0.5 + Math.random(),
          }}
        >
          ★
        </div>
      );
    }
    return elements;
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-50 via-pink-50 to-purple-50 overflow-hidden relative" dir="rtl">
      {/* Animated background stars */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="floating-star text-purple-200/40 top-1/4 left-1/4">★</div>
        <div className="floating-star text-pink-200/40 top-3/4 right-1/4">★</div>
        <div className="floating-star text-indigo-200/40 bottom-1/4 left-1/2">★</div>
        <div className="floating-star text-purple-200/30 top-1/2 right-1/3">★</div>
        <div className="floating-star text-pink-200/30 bottom-1/3 left-1/3">★</div>
      </div>

      {showConfetti && (
        <div className="fixed inset-0 pointer-events-none z-50">
          {generateConfetti()}
        </div>
      )}
      
      <div className="min-h-screen flex items-center justify-center p-4">
        <div className="relative bg-white/90 backdrop-blur-lg rounded-3xl shadow-2xl p-8 max-w-md w-full 
                      transition-all duration-700 ease-out transform hover:shadow-purple-200/50
                      border border-white/50">
          
          <div className="relative text-center">
            <h1 className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r 
                         from-purple-600 to-pink-600 mb-8">
              ربات روانشناس
            </h1>

            <div className="mb-8">
              {status === 'loading' && (
                <div className="transform hover:scale-105 transition-transform duration-300">
                  <div className="relative">
                    {/* <div className="absolute inset-0 bg-purple-500/20 rounded-full animate-ping" /> */}
                    <Loader2 className="w-16 h-16 text-purple-500 mx-auto animate-spin relative" />
                  </div>
                  <p className="mt-4 text-purple-600 animate-pulse">در حال بررسی تراکنش...</p>
                </div>
              )}
              
              {status === 'success' && details && (
                <div className="space-y-6 animate-fadeIn">
                  <div className="relative">
                    <Check className="w-16 h-16 text-green-500 mx-auto relative" />
                  </div>
                  
                  <div className="bg-white/50 backdrop-blur rounded-2xl p-6 space-y-4 
                               transform hover:scale-105 transition-all duration-300
                               border border-purple-100/50 shadow-lg">
                    <div className="text-green-600 font-bold text-lg mb-4 animate-fadeIn">
                      کیف پول شما در ربات به مبلغ {details.amount} تومان شارژ شد
                    </div>
                    <div className="flex items-center justify-between p-3 border-b border-purple-100">
                      <span className="text-gray-600">کد پیگیری:</span>
                      <span className="font-bold text-purple-700">{details.refId}</span>
                    </div>
                    <div className="flex items-center justify-between p-3 border-b border-purple-100">
                      <span className="text-gray-600">مبلغ پرداختی:</span>
                      <span className="font-bold text-purple-700">{details.amount} تومان</span>
                    </div>
                    <div className="flex items-center justify-between p-3">
                      <span className="text-gray-600">تاریخ و زمان:</span>
                      <span className="font-bold text-purple-700">{details.date}</span>
                    </div>
                  </div>
                </div>
              )}
              
              {status === 'error' && (
                <div className="transform hover:scale-105 transition-transform duration-300 space-y-4">
                  <AlertCircle className="w-16 h-16 text-red-500 mx-auto animate-bounce" />
                  <div className="space-y-4">
                    <div className="space-y-2">
                      <p className="text-red-600">{responseMessage}</p>
                      <p className="text-gray-600 text-sm">
                        لطفاً از این صفحه اسکرین‌شات تهیه کرده و برای پشتیبانی در ربات ارسال نمایید
                      </p>
                    </div>
                    
                    {details && (
                      <div className="bg-white/50 backdrop-blur rounded-2xl p-6 space-y-4 
                                   border border-red-100/50 shadow-lg text-right">
                        {details.authority && (
                          <div className="flex items-center justify-between p-3 border-b border-red-100">
                            <span className="text-gray-600">کد درخواست:</span>
                            <span className="font-bold text-red-700 text-left" style={{ direction: 'ltr' }}>
                              {details.authority}
                            </span>
                          </div>
                        )}
                        {details.refId && (
                          <div className="flex items-center justify-between p-3 border-b border-red-100">
                            <span className="text-gray-600">کد پیگیری:</span>
                            <span className="font-bold text-red-700" style={{ direction: 'ltr' }}>
                              {details.refId}
                            </span>
                          </div>
                        )}
                        {details.amount && (
                          <div className="flex items-center justify-between p-3 border-b border-red-100">
                            <span className="text-gray-600">مبلغ درخواستی:</span>
                            <span className="font-bold text-red-700">
                              {new Intl.NumberFormat('fa-IR').format(details.amount)} تومان
                            </span>
                          </div>
                        )}
                        <div className="flex items-center justify-between p-3">
                          <span className="text-gray-600">تاریخ و زمان:</span>
                          <span className="font-bold text-red-700">{details.date}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>

            {status !== 'loading' && (
              <button 
                onClick={() => {
                  window.location.href = 'https://t.me/RavanShenasRobot';
                }}
                className="group relative px-8 py-3 bg-gradient-to-r from-purple-600 to-pink-600 rounded-full 
                         text-white shadow-lg transform hover:scale-105 transition-all duration-300
                         hover:shadow-purple-300/50 overflow-hidden animate-fadeIn"
              >
                <div className="absolute inset-0 bg-white/20 group-hover:animate-shimmer" />
                <span className="relative">
                  {status === 'error' ? 'تماس با پشتیبانی' : 'بازگشت به ربات'}
                </span>
              </button>
            )}
          </div>
        </div>
      </div>
      
      <style jsx>{`
        @keyframes shimmer {
          0% { transform: translateX(100%); }
          100% { transform: translateX(-100%); }
        }
        
        @keyframes float {
          0%, 100% { transform: translate(0, 0) rotate(0deg); }
          25% { transform: translate(10px, -10px) rotate(5deg); }
          50% { transform: translate(-5px, 5px) rotate(-5deg); }
          75% { transform: translate(5px, 10px) rotate(5deg); }
        }
        
        .floating-star {
          position: absolute;
          font-size: 80px;
          line-height: 1;
          animation: float 20s infinite ease-in-out;
          filter: blur(1px);
        }
        
        .falling-star {
          position: absolute;
          color: var(--color);
          top: -20px;
          left: var(--position);
          transform: scale(var(--scale));
          animation: star-fall var(--duration) linear forwards;
          animation-delay: var(--delay);
          filter: drop-shadow(0 0 4px var(--color));
        }
        
        @keyframes star-fall {
          0% {
            transform: translateY(-10px) rotate(0deg) scale(var(--scale));
            opacity: 1;
          }
          50% {
            opacity: 1;
          }
          100% {
            transform: translateY(100vh) rotate(360deg) scale(var(--scale));
            opacity: 0;
          }
        }

        @keyframes fadeIn {
          from { opacity: 0; transform: translateY(20px); }
          to { opacity: 1; transform: translateY(0); }
        }

        .animate-fadeIn {
          animation: fadeIn 0.5s ease-out forwards;
        }
      `}</style>
    </div>
  );
};

export default PaymentVerification;